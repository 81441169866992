<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-6">
        <a-tabs :activeKey="activeKey" @change="onChange">
          <a-tab-pane v-for="tab in tabs" :key="tab.value" :tab="tab.name"></a-tab-pane>
        </a-tabs>
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabs: [
        { name: 'Profile', value: 'account' },
        { name: 'Password', value: 'account-password' },
      ],
    }
  },

  computed: {
    activeKey() {
      return this.$route.name
    },
  },

  methods: {
    onChange(key) {
      this.$router.push({ name: key })
    },
  },
}
</script>
